import './App.css';
import Button from "@mui/material/Button";
import DatePickerComponent from "./Components/DatePicker";
import NavAppBar from './Components/AppBar';

function App() {
  return (
      <div className="App">
          <NavAppBar></NavAppBar>
          <h1>Research React Project</h1>
          <h2>Material UI components: </h2>
          <h3>Test</h3>
          <DatePickerComponent></DatePickerComponent>
          <br />
          <br />
          <Button variant="contained" sx={{ backgroundColor: 'green' }}>Contained Button</Button>
    </div>
  );
}

export default App;
